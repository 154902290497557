import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Page from '../../layout/components/Page/Page.component';
import styles from './Reports.module.css';
import Icon from '../../components/Icon/Icon.component';
import {
  DataGrid,
  GridCallbackDetails,
  GridPaginationModel,
} from '@mui/x-data-grid';
import { Filter } from '../../components/Filter';
import ReportMS from '../../services/ms/ReportMS.service';
import { ReportMSTypes } from '../../types/definitions';
import { useSearchParams } from 'react-router-dom';
import useFilters from '../../hooks/useFilters.hook';
import OperationMS from '@spot-spotsat/services/ms/OperationMS.service';

type ReportsData = {
  reports: ReportMSTypes.RegisterBlockchains['results']['rows'];
};

function Reports(): JSX.Element {
  const [searchParams] = useSearchParams({
    start_created_at: new Date('2020-01-01').toISOString(),
    end_created_at: new Date().toISOString(),
  });
  const [data, setDate] = useState<ReportsData>({ reports: [] });
  const [total_operation, setTotalOperation] = useState<number>(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 20,
  });

  const { clearFilters, addFilter, applyFilters } = useFilters();
  const [isGeneratingReport, setIsGeneratingReport] = useState<{
    [key: string]: boolean;
  }>({});

  const [stateFilters, setStateFilters] = useState({
    start_created_at: '',
    end_created_at: '',
    bacen_code: '',
    code: '',
    document_number: '',
  });

  function handleClearFilters() {
    clearFilters();
    setStateFilters({
      start_created_at: '',
      end_created_at: '',
      bacen_code: '',
      code: '',
      document_number: '',
    });
  }

  let isLoading = useRef<boolean>(true);

  useEffect(() => {
    isLoading.current = true;
    async function fetchData() {
      isLoading.current = true;

      const { page, pageSize } = paginationModel;
      const offset = page * pageSize;
      const limit = pageSize;

      const newSearchParams: URLSearchParams = new URLSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        offset: offset.toString(),
        limit: limit.toString(),
      });

      const responses = await Promise.allSettled([
        ReportMS.RegisterBlockchains(newSearchParams),
      ]);

      responses.forEach((response, index) => {
        if (response.status === 'fulfilled') {
          if (response.value === false) return;

          if (index === 0) {
            setDate((prev: ReportsData) => ({
              ...prev,
              reports: (response.value as ReportMSTypes.RegisterBlockchains)
                .results.rows,
            }));
            setTotalOperation(
              (response.value as ReportMSTypes.RegisterBlockchains).metadata
                .resultset.count
            );
          }
        }
      });

      isLoading.current = false;
    }

    fetchData();
  }, [searchParams, paginationModel]);

  function onPaginationModelChange(
    model: GridPaginationModel,
    details: GridCallbackDetails
  ): void {
    setPaginationModel(model);
    isLoading.current = true;
  }

  async function downloadReport(row: any) {
    if (!row) return;

    setIsGeneratingReport((prevState) => ({
      ...prevState,
      [row.code]: true,
    }));

    const document_number = row.code;
    const start_created_at = new Date('2015-01-01').toISOString();
    const end_created_at = new Date().toISOString();

    const params = new URLSearchParams();

    params.append('document_number', document_number);
    params.append('start_created_at', start_created_at);
    params.append('end_created_at', end_created_at);

    const operations = await OperationMS.All(params);

    if (!operations || operations.results.count === 0) return;

    const operation = operations.results.rows[0];

    try {
      await OperationMS.Report(String(operation.id));

      setIsGeneratingReport((prevState) => ({
        ...prevState,
        [row.code]: false,
      }));
    } catch (error) {
      setIsGeneratingReport((prevState) => ({
        ...prevState,
        [row.code]: false,
      }));
      return;
    }
  }

  const columns = [
    { field: 'code', headerName: 'Operação', flex: 0, sortable: false },
    { field: 'bacen', headerName: 'Bacen', flex: 1, minWidth: 120 },
    {
      field: 'created',
      headerName: 'Data de geração',
      flex: 1,
      minWidth: 160,
      valueGetter: (params: string) =>
        new Date(params).toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        }),
    },
    {
      field: 'report',
      headerName: 'Reenvio de relatório',
      flex: 1,
      minWidth: 270,
      renderCell: (params: any) =>
        !isGeneratingReport[params.row.code] ? (
          <button
            className={styles.downloadButton}
            onClick={() => downloadReport(params.row)}
          >
            <Icon name="Email" /> Receber relatório por email
          </button>
        ) : (
          <button
            className={styles.downloadButton}
            onClick={() => downloadReport(params.row)}
          >
            Relatório enviado!
          </button>
        ),
    },
  ];

  return (
    <Page
      breadcrumbs={{
        icon: 'File',
        breadcrumbs: [{ label: 'Relatório', href: '/', active: true }],
      }}
    >
      <Filter.Root>
        <Filter.Group>
          <Filter.Date
            label="Início"
            className={styles.filterItems}
            onChange={(event) => {
              const target = event.target as HTMLDataElement;
              addFilter('start_created_at', target.value);
              setStateFilters({
                ...stateFilters,
                start_created_at: target.value,
              });
            }}
            value={stateFilters['start_created_at'] || ''}
          />
          <Filter.Date
            label="Fim"
            className={styles.filterItems}
            onChange={(event) => {
              const target = event.target as HTMLDataElement;
              addFilter('end_created_at', target.value);
              setStateFilters({
                ...stateFilters,
                end_created_at: target.value,
              });
            }}
            value={stateFilters['end_created_at'] || ''}
          />
          <Filter.Text
            label="Bacen"
            className={styles.filterItems}
            onChange={(event) => {
              const target = event.target as HTMLDataElement;
              const sanitizedValue = target.value
                .replace(/\D/g, '')
                .slice(0, 20);
              addFilter('bacen_code', sanitizedValue);
              setStateFilters({ ...stateFilters, bacen_code: sanitizedValue });
            }}
            value={stateFilters['bacen_code'] || ''}
          />
          <Filter.Text
            label="Operação"
            className={styles.filterItems}
            onChange={(event) => {
              const target = event.target as HTMLDataElement;
              const sanitizedValue = target.value
                .replace(/\D/g, '')
                .slice(0, 10);
              addFilter('code', sanitizedValue);
              setStateFilters({ ...stateFilters, code: sanitizedValue });
            }}
            value={stateFilters['code'] || ''}
          />
          <Filter.Text
            label="CPF"
            type="text"
            className={styles.filterItems}
            onChange={(event) => {
              const target = event.target as HTMLDataElement;
              const sanitizedValue = target.value
                .replace(/\D/g, '')
                .slice(0, 11);
              addFilter('document_number', sanitizedValue);
              setStateFilters({
                ...stateFilters,
                document_number: sanitizedValue,
              });
            }}
            value={stateFilters['document_number'] || ''}
          />
        </Filter.Group>
        <Filter.Group type="flex">
          <Filter.Button
            label="Limpar Filtros"
            iconName="Close"
            variant="outline-secondary"
            className={styles.filterButton}
            onClick={handleClearFilters}
          />
          <Filter.Button
            label="Filtrar"
            iconName="Filter"
            variant="filled-secondary"
            className={styles.filterButton}
            onClick={applyFilters}
          />
        </Filter.Group>
      </Filter.Root>

      <div className={styles.tableOperations}>
        <DataGrid
          loading={isLoading.current}
          initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          pageSizeOptions={[20]}
          columns={columns}
          rowCount={total_operation}
          rows={data.reports}
          sx={{ '& .MuiDataGrid-main': { width: 0, minWidth: '100%' } }}
          disableColumnResize
          disableColumnMenu
          autoHeight
        />
      </div>
    </Page>
  );
}

export default Reports;
